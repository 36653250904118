import { usePortal } from "./usePortal";
import { PortalID } from "./portal-types";
import * as ReactDOM from "react-dom";
import { CSSProperties, useLayoutEffect, useRef, useState } from "react";

export default function Modal({
  children,
  dimBackground = false,
  blurBackground = false,
  withPortal = true,
  portalID = PortalID.Modal,
  zIndex,
  theme,
  customStyle,
  onClickBackground,
  type,
}: {
  children: any;
  dimBackground?: boolean;
  blurBackground?: boolean;
  withPortal?: boolean;
  portalID?: string;
  zIndex?: number;
  theme?: "dark" | "light";
  customStyle?: CSSProperties;
  onClickBackground?: () => void;
  type?: string;
}) {
  theme = theme || "dark";

  const backgroundRef = useRef<HTMLDivElement>(null);

  function renderContent() {
    return (
      <div
        className="bg"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center",
          backdropFilter: blurBackground ? "blur(6px)" : undefined,
          backgroundColor: dimBackground ? getBackgroundStyle() : "transparent",
          pointerEvents: type !== "walkthrough-tip" ? "auto" : "none",
          zIndex,
          ...customStyle,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: -1,
            pointerEvents: type !== "walkthrough-tip" ? "auto" : "none",
          }}
          ref={backgroundRef}
          onClick={onClickBackground}
        />
        {children}
      </div>
    );
  }

  function getBackgroundStyle() {
    switch (theme) {
      case "dark": {
        return "rgba(17,51,87,0.65)";
      }
      case "light": {
        return "rgba(255,255,255,0.5)";
      }
      default: {
        return "rgba(0,0,0,0.2)";
      }
    }
  }

  // This is a workaround to a problem with react portals:
  // https://github.com/reactjs/reactjs.org/issues/272
  const [isMounted, setIsMounted] = useState(false);
  useLayoutEffect(() => {
    setIsMounted(true);
  }, []);

  const target = usePortal(portalID);

  if (!withPortal) return renderContent();
  return ReactDOM.createPortal(isMounted ? renderContent() : null, target);
}

import { Reflect } from "@workcanvas/reflect/client";
import { useEffect } from "react";
import { accountMutators, AM, getCustomColorsForUser } from "shared/datamodel/account-mutators";
import { useSubscribe } from "replicache-react";
import { accountAtom, userAtom } from "state-atoms";
import { atom, useAtom, useAtomValue } from "jotai";

export const accountRepsAtom = atom<any>(null);

export function useAccountReps() {
  const user = useAtomValue(userAtom);
  const account = useAtomValue(accountAtom);
  const [accountReps, setAccountReps] = useAtom(accountRepsAtom);

  const isReady = !!accountReps;
  const reps = accountReps as Reflect<AM>;

  useEffect(() => {
    if (user?.repsToken && account && !isReady) {
      const workerOrigin = process.env.PUBLIC_WORKER_HOST!;
      const roomId = `account/${account.id}`;
      const r = new Reflect<AM>({
        socketOrigin: workerOrigin,
        userID: user.id,
        roomID: roomId,
        auth: JSON.stringify({
          authToken: user.repsToken,
          documentId: roomId,
          userId: user.id,
        }),
        mutators: accountMutators,
        logLevel: "error",
        hiddenTabDisconnectDelay: 1000 * 60 * 10, // 10 minutes
        enableAnalytics: false,
      });
      setAccountReps(r);
    }
  }, [user, account, isReady]);

  async function getBoardMetadata(boardId: string) {
    if (!reps) {
      return null;
    }
    return await reps.mutate.getBoardMetadata(boardId);
  }

  async function updateBoardLastChanged(boardId: string, snapshotImage: string | null = null) {
    if (!reps) {
      return;
    }
    await reps.mutate.updateLastChangedForBoard({ boardId, snapshotImage });
  }

  async function updateBoardLastVisited(boardId: string) {
    if (!reps) {
      return;
    }
    await reps.mutate.updateVisitedForUser({ boardId, userId: user!.id });
  }

  function useCustomColorsForUser() {
    return useSubscribe(reps, async (tx) => getCustomColorsForUser(tx, user!.id), []);
  }

  async function addCustomColorForUser(color: string) {
    if (!reps) {
      return;
    }
    await reps.mutate.addCustomColorForUser({ color, userId: user!.id });
  }

  async function removeCustomColorForUser(index: number) {
    if (!reps) {
      return;
    }
    await reps.mutate.removeCustomColorForUser({ index, userId: user!.id });
  }

  async function getBoardsData() {
    if (!reps) {
      return;
    }
    return await reps.mutate.getBoardsData();
  }

  async function resetBoardData(boardId: string) {
    if (!reps) {
      return;
    }
    await reps.mutate.resetBoardData(boardId);
  }

  return {
    isAccountRepsReady: isReady,
    accountRepsInstance: reps,
    getBoardMetadata,
    updateBoardLastChanged,
    updateBoardLastVisited,
    useCustomColorsForUser,
    addCustomColorForUser,
    removeCustomColorForUser,
    getBoardsData,
    resetBoardData,
  };
}

import { loadBoard } from "frontend/services/boardsService";
import useStateValue from "frontend/state/value";
import { useEffect, useState } from "react";
import { Board, BoardPermission } from "shared/datamodel/schemas/board";
import useAuthentication from "./use-authentication";
import { useTeam } from "./use-team";
import { Team } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import { authTokenAtom, isInIframeAtom } from "state-atoms/general-atoms";
import { useSetAtom } from "jotai";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";
import { iframeHasSessionToken } from "utils/url-utils";

export default function useBoard({
  documentId,
  authRequired = false,
  password,
  isPrivateByCreator = false,
}: {
  documentId: string;
  authRequired?: boolean;
  password?: string;
  isPrivateByCreator?: boolean;
}) {
  const [{ boards, teams, account }, dispatch] = useStateValue();
  const [board, setBoard] = useState<Board | null>(null);
  const [loaded, setIsLoaded] = useState(false);
  const [templateElements, setTemplateElements] = useState<Record<string, any> | null>(null);
  const [failedLoading, setFailedLoading] = useState(false);
  const [isDeletedBoard, setDeletedBoard] = useState(false);
  const [isPasswordProtected, setPasswordProtectedBoard] = useState(false);
  const [protectedBoardReady, setProtectedBoardReady] = useState(false);
  const { isReady, triggerAuthentication } = useAuthentication(authRequired);
  const { myTeams, getTeam } = useTeam();
  const setAuthToken = useSetAtom(authTokenAtom);
  const isNewHomePageEnabled = useFeatureFlag("home-page-refactor");
  const setIsInIframe = useSetAtom(isInIframeAtom);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const board = boards.find((board) => board.documentId === documentId);
    if (board) {
      setBoard(board);
    } else if (!loaded) {
      setIsInIframe(iframeHasSessionToken());
      loadBoard(dispatch, documentId, password, isPrivateByCreator)
        .then(({ board, templateElements, authToken }) => {
          setTemplateElements(templateElements);
          setIsLoaded(true);
          setBoard(board);
          setProtectedBoardReady(true);
          authToken && setAuthToken(authToken);
        })
        .catch((e) => {
          if (e.response?.status == 303) {
            setPasswordProtectedBoard(true);
          }
          if (e.response?.status == 401) {
            triggerAuthentication();
          } else if (e.response?.status == 410) {
            setDeletedBoard(true);
          } else if (e.response?.status == 400) {
            setProtectedBoardReady(true);
            setFailedLoading(true);
          } else if (isNewHomePageEnabled && e.response?.status == 403) {
            setProtectedBoardReady(true);
            setFailedLoading(true);
          } else {
            setFailedLoading(true);
          }
        });
    } else {
      setFailedLoading(true);
    }
  }, [isReady, boards, password]);

  function getBoardTeamAndProject(): { team: Team; project: Project | null } {
    let boardTeam = null;
    let boardProject = null;
    if (board?.projectId) {
      for (const team of myTeams) {
        boardProject = team?.projects?.find((project) => project.id === board?.projectId);
        if (boardProject) {
          boardTeam = team;
          break;
        }
      }
    }
    boardTeam ??= getTeam(board?.teamId);
    return { team: boardTeam, project: boardProject };
  }

  function checkAccessByAccount() {
    if (board?.accountId === account?.id) {
      return true;
    }
  }

  function hasAccessByTeamOrProject() {
    if (!checkAccessByAccount()) {
      return false;
    }
    if (!board?.projectId && !board?.teamId) {
      return true;
    }
    if (board?.projectId) {
      return teams.some((t) => t.projects?.some((p) => p.id === board.projectId));
    }

    if (board?.teamId) {
      return teams.some((t) => t.id === board.teamId);
    }

    return false;
  }

  function hasPublicAccess() {
    return board?.permission === BoardPermission.public || board?.permission === BoardPermission.publicReadOnly;
  }

  return {
    isReady,
    board,
    failedLoading,
    isDeletedBoard,
    isPasswordProtected,
    protectedBoardReady,
    templateElements,
    getBoardTeamAndProject,
    hasAccessByTeamOrProject,
    hasPublicAccess,
  };
}
